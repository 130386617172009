@import '../abstracts/extends';
@import '../abstracts/variables';
@import '../components/menu-profile-landing';
@import '../components/pay-items';
@import '../mixins/mixins';

.not-author {
  .open-cases {
    // background: url(../images/open-cases-bcgr.png) 50% 100% no-repeat;
    padding-bottom: 50px;
    min-height: 730px;
    position: relative;
    overflow: hidden;
    //padding-top: 30px;
    //margin-top: -30px;
    //margin-bottom: -80px;
    z-index: 3;
    background: linear-gradient(
                    #040817 0%,
                    #3d0044 36.35%,
                    #420051 52.67%,
                    #39024e 65.43%,
                    #230243 81.74%,
                    #040817 100%
    );

    h1 {
      text-shadow: 0 0 80px #ffc600;
      font-size: 40px;
      font-weight: 900;
      line-height: 1;
      text-align: center;
      color: #ffc600;
      margin: 32px 0 15px 0;
    }

    h3 {
      text-shadow: -1px -1px 0 #d32ce6, 1px -1px 0 #d32ce6, -1px 1px 0 #d32ce6,
      1px 1px 0 #d32ce6, 0 0 24px #d32ce6;
      font-size: 24px;
      font-weight: 900;
      line-height: 1;
      text-align: center;
      color: $white;
      text-transform: uppercase;
      margin-bottom: -15px;
    }

    &:before {
      @extend %before-after;
      background: url(../images/terror-1.png) 0 0 no-repeat;
      width: 549px;
      height: 628px;
      top: 0;

      @media(max-width: 1200px) {
        display: none !important;
      }
    }

    &:after {
      @extend %before-after;
      background: url(../images/terror-2.png) 0 0 no-repeat;
      width: 549px;
      height: 628px;
      right: 0;
      top: 0;

      @media(max-width: 1200px) {
        display: none !important;
      }
    }

    .login-btns {
      display: none;

      @media(max-width: 1200px) {
        display: flex;
        justify-content: center;
        margin-bottom: 110px;
        padding: 0;
      }
    }

    .open-cases-btns {
      @media(max-width: 1200px) {
        display: none;
      }
    }
  }
}

.profile-landing {
  position: relative;

  @include before() {
    width: 100%;
    height: 100%;
    max-height: 900px;
    background: linear-gradient(to top, rgba(11, 2, 32, 0.0001) 0%, rgba(44, 6, 87, 0.7) 70%, #020111 100%);
    top: -160px;
    z-index: -1;
    pointer-events: none;
  }
  @include after() {
    top: -160px;
    width: 100%;
    height: 280px;
    background: radial-gradient(342.86% 100% at 50% 0%, rgba(211, 44, 230, 0.4) 0%, rgba(62, 8, 85, 0.0001) 100%);
    pointer-events: none;
    z-index: -1;
  }
}

.authorization {
  padding-left: 135px;
  overflow: hidden;

  .open-cases-btns {
    margin-bottom: -11px;

    .advantages-sign-in-steam {
      .bot-2 {
        bottom: -14px;
        left: 20px;
        transform: rotate(-9deg);
      }

      &:hover {
        .bot-2 {
          bottom: -18px;
          left: 8px;
        }
      }
    }
  }
}

.lang {
  position: absolute;
  top: 0;
  left: -99px;
  width: 96px;
  cursor: pointer;
  z-index: 7;

  &:before {
    @extend %before-after;
    height: 3px;
    width: 100%;
    top: 100%;
    left: 0;
  }

  .material-icons {
    height: 24px;
    width: 24px;
    transition: all 0.5s;
    color: rgba(230, 155, 255, 0.4);
    position: relative;
    top: -2px;
  }

  & > :first-child {
    position: relative;
    top: -3px;
    width: 96px;
    height: 40px;
    display: flex;
    align-items: center;

    .lang-name {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      text-transform: uppercase;
      padding-left: 24px;
      padding-right: 10px;
    }

    &:before {
      @extend %before-after;
      background: url(../images/bg-lang.svg) no-repeat 0 0;
      width: 98px;
      height: 35px;
      transition: all .5s;
    }

    &:after {
      @extend %before-after;
      background: url(../images/bg-lang-hover.svg) no-repeat 0 0;
      width: 98px;
      height: 35px;
      opacity: 0;
      transition: all .5s;
      z-index: -1;
    }

    img {
      width: 20px;
      display: block;
      height: auto;
      position: relative;
      z-index: 2;
      margin-bottom: 7px;
      margin-left: -9px;
    }
  }

  &-drop {
    border: 4px solid #6001AE;
    background: linear-gradient(325.23deg, #08132E 12.81%, #340A67 86.53%);
    position: absolute;
    top: 100%;
    left: 0;
    display: none;
    width: 74px;
    margin-top: -1px;

    &-wrap {
      overflow: auto;
      height: 170px;

      &::-webkit-scrollbar {
        width: 3px;

        &-track {
          background: #221038;
        }

        &-thumb {
          border: 3px solid #290261;
        }
      }

      .lang-item {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 6px 16px 6px 10px;
        cursor: pointer;
        height: 27px;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        text-transform: uppercase;

        span {
          margin-left: 6px;
        }

        &:hover,
        &.active {
          background: linear-gradient(
                          to right,
                          #770484 0%,
                          #60006b 49.88%,
                          rgba(32, 3, 57, 0) 100%
          );
        }

        img {
          width: 20px;
          height: auto;
          display: block;
        }
      }
    }
  }

  &:hover {
    .lang-drop {
      display: block;
    }

    .material-icons {
      transform: rotate(180deg);
    }

    .lang-item {
      &:before {
        opacity: 0;
      }

      &:after {
        opacity: 1;
      }
    }
  }
}

.site-sound {
  position: absolute;
  top: 0;
  left: 10px;
  background: url(../images/bg-lang.svg) no-repeat 0 0;
  width: 98px;
  height: 35px;
  cursor: pointer;
  z-index: 3;

  &:before {
    @extend %before-after;
    background: url(../images/bg-lang-hover.svg) no-repeat 0 0;
    transition: all .5s;
    width: 100%;
    height: 100%;
    opacity: 0;
  }

  span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 18px;
    height: 18px;
    margin-left: -3px;
    margin-top: -2px;

    &:after,
    &:before {
      @extend %before-after;
      background: url(../images/site-sound-on.svg) no-repeat 0 0;
      width: 100%;
      height: 100%;
      opacity: 1;
      transition: opacity .5s;
    }

    &:after {
      opacity: 0;
      background: url(../images/site-sound-off.svg) no-repeat 0 0;
    }
  }

  &:hover {
    &:before {
      opacity: 1;
    }
  }

  &.active {
    span {
      &:before {
        opacity: 0;
      }

      &:after {
        opacity: 1;
      }
    }
  }
}

.page-profile-landing {
  .profile-landing {
    @media(max-width: 1240px) {
      padding: 0 20px;
    }
  }

  .open-cases {
    padding-top: 110px;
    margin-bottom: -210px;
    background: none;
    padding-bottom: 50px;
    min-height: 730px;
    position: relative;
    overflow: hidden;
    margin-top: 0;
    z-index: 3;

    @media(max-width: 1200px) {
      padding-top: 30px;
      min-height: 600px;
      margin-bottom: -280px;
      overflow: visible;
    }
    @media(max-width: 640px) {
      padding-top: 30px;
      min-height: 570px;
      margin-bottom: -280px;
    }

    h1 {
      @media(max-width: 1200px) {
        text-shadow: 0px 0px 24px rgba(255, 198, 0, 0.64);
      }
      @media(max-width: 640px) {
        font-size: 28px;
        margin-top: 0;
      }
    }

    h3 {
      margin-bottom: 82px;

      @media(max-width: 1200px) {
        margin-bottom: 42px !important;
      }
      @media(max-width: 640px) {
        font-size: 18px;
        line-height: 1.2;
      }
    }
  }
}

.about-project-wrap {
  margin-bottom: 129px;

  @media(max-width: 1200px) {
    margin-bottom: 20px;
  }

  h2 {
    font-weight: bold;
    font-size: 30px;
    line-height: 30px;
    text-align: center;
    text-transform: uppercase;
    color: #f7dbff;
    text-shadow: -1px -1px 0 #c131e4, 1px -1px 0 #c131e4, -1px 1px 0 #c131e4,
    1px 1px 0 #c131e4, 0 0 36px #c131e4, 0px 0px 3px #c131e4;
    margin-bottom: 34px;

    @media(max-width: 640px) {
      font-size: 24px;
      line-height: 1;
    }
  }
}

.about-project {
  max-width: 960px;
  margin: 0 auto 63px auto;
  position: relative;
  z-index: 3;

  &-content {
    font-size: 16px;
    line-height: 24px;
    color: #b36fff;
    margin-bottom: 10px;
    padding: 22px 20px 0 20px;
    background: rgba(211, 44, 230, 0.08);
    border: 2px solid rgba(211, 44, 230, 0.2);
    border-radius: 20px;

    @media(max-width: 640px) {
      font-size: 14px;
    }

    p {
      margin-bottom: 23px;
    }

    & > :last-child {
      margin-bottom: 13px;
    }
  }
}

.about-benefits {
  margin: 0 auto;
  max-width: 960px;

  &-items {
    display: flex;
    margin: 0 -16px;

    @media(max-width: 992px) {
      padding: 0 16px;
    }
    @media(max-width: 640px) {
      flex-direction: column;
      align-items: center;
      gap: 24px;
    }
  }

  &-item {
    margin: 0 16px;
    width: calc(33.3% - 32px);

    @media(max-width: 992px) {
      text-align: center;
    }
    @media(max-width: 640px) {
      width: 100%;
    }

    &-icon {
      margin-bottom: 14px;
    }

    &-title {
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;
      color: #ffffff;
      text-shadow: 0px 0px 48px #d32ce6;
      margin-bottom: 12px;

      @media(max-width: 640px) {
        font-size: 18px;
      }
    }

    &-num {
      font-weight: bold;
      font-size: 28px;
      line-height: 24px;
      letter-spacing: 0.7px;
      color: #ffffff;
      text-shadow: 0px 0px 48px #d32ce6;
      margin-bottom: 21px;

      @media(max-width: 640px) {
        font-size: 20px;
      }
    }

    &-text {
      font-size: 16px;
      line-height: 24px;
      color: #5f4169;
    }
  }
}

.hr-benefits {
  width: 960px;
  height: 2px;
  background: linear-gradient(
                  90deg,
                  rgba(211, 44, 230, 0.0001) 0%,
                  #d32ce6 50%,
                  rgba(211, 44, 230, 0.0001) 100%
  );
  mix-blend-mode: normal;
  opacity: 0.2;
  margin: 57px auto 65px auto;

  @media(max-width: 1024px) {
    width: 100%;
  }
}

.profile-landing-user-header {
  max-width: 1170px;
  margin: 0 auto;
  padding-top: 70px;
  position: relative;

  @media(max-width: 1240px) {
    padding: 70px 0 0 0;
  }
  @media(max-width: 992px) {
    padding-top: 40px;
  }

  h1 {
    font-weight: 500;
    font-size: 28px;
    line-height: 24px;
    text-align: center;
    color: #ffc600;
    margin-bottom: 40px;
  }
}

.profile-landing-user-stats {
  padding: 0 247px;
  display: flex;
  margin: 0 -32px;
  position: relative;

  @media(max-width: 1200px) {
    padding: 0;
    margin: 0;
    justify-content: center;
  }
  @media(max-width: 768px) {
    flex-wrap: wrap;
    gap: 20px;
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    bottom: -30px;
    left: 0;
    width: 100%;
    height: 208px;
    background: radial-gradient(
                    50% 79.2% at 50% 100%,
                    rgba(230, 44, 199, 0.24) 0%,
                    rgba(21, 5, 49, 0.0001) 100%
    );
    opacity: 0.4;
  }

  & > :first-child {
    margin-right: 96px;
    position: relative;

    @media(max-width: 768px) {
      margin: 0;
    }

    @include before() {
      width: 1px;
      height: 64px;
      background: radial-gradient(
                      50% 50% at 50% 50%,
                      #b0c3d9 0%,
                      rgba(176, 195, 217, 0.0001) 100%
      );
      mix-blend-mode: normal;
      opacity: 0.4;
      top: 50%;
      left: auto;
      right: -64px;
      transform: translateY(-50%);

      @media(max-width: 768px) {
        display: none;
      }
    }
  }
}

.profile-landing-user-stats-item {
  margin: 0 32px;

  @media(max-width: 768px) {
    width: calc(50% - 10px);
    margin: 0;
  }

  .num {
    font-weight: bold;
    font-size: 28px;
    line-height: 24px;
    letter-spacing: 0.7px;
    color: #a97aff;
    display: flex;
    align-items: center;
    margin-bottom: 5px;

    @media(max-width: 768px) {
      justify-content: center;
      font-size: 24px;
    }
    @media(max-width: 480px) {
      justify-content: flex-start;
    }

    .icon {
      margin-right: 8px;
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: linear-gradient(300deg, #8214a8, #ff6ce3);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

      img {
        width: 32px;
        height: 32px;
      }
    }
  }

  .text {
    font-size: 16px;
    line-height: 24px;
    color: rgba(230, 155, 255, 0.4);
    display: flex;
    padding-left: 6px;
    white-space: nowrap;

    @media(max-width: 768px) {
      justify-content: center;
      padding: 0;
    }
    @media(max-width: 480px) {
      justify-content: flex-start;
    }

    .cscaseCur {
      margin-left: 5px;
    }
  }
}

.profile-landing-user-menu {
  position: relative;
  margin-top: 30px;
  margin-bottom: 40px;

  .menu-user {
    padding-top: 2px;

    @media(max-width: 992px) {
      overflow: hidden;
      overflow-x: auto;
      margin: 0 -20px;
      padding-bottom: 0;
      height: 68px;

      ul {
        flex-wrap: nowrap;
        padding: 0 10px;

        li {
          overflow: visible;
          padding: 0 10px;
          margin: 0;
        }

        a {
          white-space: nowrap;
        }
      }
    }
    @media(max-width: 670px) {
      ul {
        justify-content: flex-start;
      }
    }

    &::-webkit-scrollbar {
      width: 0;
    }
  }

  &:before {
    @extend %before-after;
    width: 100%;
    height: 2px;
    top: 0;
    left: 0;
    background: linear-gradient(
                    90deg,
                    rgba(32, 8, 66, 0.0001) 0%,
                    #860b95 50%,
                    #870b95 50%,
                    rgba(32, 8, 66, 0.0001) 100%
    );
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    li {
      position: relative;
      margin: 0 20px;
      overflow: hidden;

      @media(max-width: 992px) {
        margin: 0 10px;
      }

      a {
        min-height: 68px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        font-weight: bold;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.325px;
        text-transform: uppercase;
        color: #cccccc;
        opacity: 0.4;
        text-decoration: none;
        transition: all 0.5s;

        @include before() {
          width: 8px;
          height: 8px;
          top: -4px;
          left: 50%;
          transform: translateX(-50%);
          background: #d32ce6;
          border-radius: 4px;
          opacity: 0;
          transition: all 0.5s;
        }
        @include after() {
          width: 136px;
          height: 64px;
          background: url(../images/profile-landing-user-menu-active.svg) no-repeat 0 0;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
          z-index: -1;
          opacity: 0;
        }
      }

      &:hover,
      &.active {
        a {
          color: #d32ce6;
          opacity: 1;

          &:before {
            opacity: 1;
          }

          &:after {
            opacity: 1;
          }
        }
      }
    }
  }
}

.profile-promocodes {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -16px 64px -16px;
  position: relative;

  @media(max-width: 1240px) {
    margin: 0 0 32px 0;
  }

  &-item {
    width: calc(50% - 32px);
    margin: 0 16px;
    height: 433px;
    border-radius: 32px;
    position: relative;

    @media(max-width: 992px) {
      height: auto;
      width: 100%;
      margin: 0;
    }
    @include before() {
      width: 32px;
      height: 64px;
      background: #13072c;
      top: 50%;
      left: -2px;
      transform: translateY(-50%);
      border: 2px solid #3f0047;
      border-radius: 0 100% 100% 0 / 0 50% 50% 0;
      border-left: 2px solid #13072c;
      margin-top: 20px;

      @media(max-width: 992px) {
        display: none;
      }
    }
    @include after() {
      width: 32px;
      height: 64px;
      background: #13072c;
      top: 50%;
      left: auto;
      right: -2px;
      transform: translateY(-50%);
      border: 2px solid #3f0047;
      border-radius: 100% 0 0 100% / 50% 0 0 50%;
      border-right: 2px solid #13072c;
      margin-top: 20px;

      @media(max-width: 992px) {
        display: none;
      }
    }
  }

  &-add {
    width: calc(50% - 32px);
    margin: 0 16px;
    border: 4px solid rgba(136, 71, 255, 0.16);
    border-radius: 32px;
    height: 433px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: rgba(136, 71, 255, 0.4);
    transition: all 0.5s;

    @media(max-width: 992px) {
      width: 100%;
      margin: 0;
      height: 420px;
    }

    .icon {
      font-size: 60px;
      line-height: 60px;
      margin-bottom: 22px;
    }

    .text {
      font-weight: bold;
      font-size: 24px;
      line-height: 24px;
      text-align: center;
      text-transform: uppercase;
      margin-bottom: 12px;
    }

    .text-counter {
      font-weight: bold;
      font-size: 16px;
      line-height: 16px;
      text-align: center;
    }

    &:hover {
      border-color: rgba(136, 71, 255, 0.32);
      color: rgba(136, 71, 255, 1);
    }
  }
}

.profile-promocodes-item {
  background: linear-gradient(180deg, #1d0544 0%, #130333 100.23%);
  border: 2px solid #3f0047;
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;

  &.opacity {
    opacity: 0.5;
  }

  &-input {
    padding: 28px 32px 14px 32px;

    @media(max-width: 768px) {
      padding: 20px 20px 14px 20px;
    }

    &:first-child .profile-promocodes-item-input-wrap > input {
      text-transform: uppercase;
    }

    .label {
      font-size: 14px;
      line-height: 16px;
      color: rgba(230, 155, 255, 0.4);
      margin-bottom: 4px;
    }

    &-wrap {
      position: relative;

      .percent {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        font-weight: bold;
        font-size: 18px;
        line-height: 28px;
        text-align: right;
        color: #d32ce6;
        text-shadow: 0px 0px 24px #d32ce6;
      }

      .link-copy {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        font-size: 24px;
        line-height: 24px;
        color: rgba(230, 155, 255, 0.4);
        display: block;
        width: 24px;
        height: 24px;
        transition: all 0.5s;

        &:hover {
          color: #e69bff;
        }
        span {
          text-decoration: none;
          font-family: "Roboto", sans-serif;
          position: absolute;
          top: -20px;
          right: 0;
          font-size: 14px;
          line-height: 16px;
          opacity: 0;
          transition: all .3s;
        }
        &.active {
          span {
            opacity: 1;
          }
        }
      }

      input {
        padding-left: 40px;
        background: none;
        font-weight: bold;
        font-size: 18px;
        line-height: 28px;
        color: #d32ce6;
        border: 0;
        width: 100%;

        @media(max-width: 520px) {
          width: calc(100% - 30px);
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &::-webkit-input-placeholder {
          font-size: 16px;
          line-height: 19px;
        }

        &::-moz-placeholder {
          font-size: 16px;
          line-height: 19px;
        }

        &:-ms-input-placeholder {
          font-size: 16px;
          line-height: 19px;
        }

        &:-moz-placeholder {
          font-size: 16px;
          line-height: 19px;
        }

        &:focus {
          outline: none;
        }
      }

      .icon {
        position: absolute;
        top: -2px;
        left: 0;
      }

      &:before {
        @extend %before-after;
        background: linear-gradient(90deg, #8c009c 0%, #500067 100%);
        width: 100%;
        height: 2px;
        top: 100%;
        margin-top: 4px;
      }
    }

    &.error {
      input {
        &::-webkit-input-placeholder {
          color: rgba(235, 75, 75, 0.4);
        }

        &::-moz-placeholder {
          color: rgba(235, 75, 75, 0.4);
        }

        &:-ms-input-placeholder {
          color: rgba(235, 75, 75, 0.4);
        }

        &:-moz-placeholder {
          color: rgba(235, 75, 75, 0.4);
        }
      }

      .profile-promocodes-item-input-wrap {
        &:before {
          background: linear-gradient(
                          90deg,
                          #eb4b4b 0%,
                          rgba(235, 75, 75, 0.4) 100%
          );
        }
      }
    }

    &.empty {
      input {
        &::-webkit-input-placeholder {
          color: rgba(230, 155, 255, 0.4);
        }

        &::-moz-placeholder {
          color: rgba(230, 155, 255, 0.4);
        }

        &:-ms-input-placeholder {
          color: rgba(230, 155, 255, 0.4);
        }

        &:-moz-placeholder {
          color: rgba(230, 155, 255, 0.4);
        }
      }

      .profile-promocodes-item-input-wrap {
        &:before {
          background: linear-gradient(
                          90deg,
                          rgba(230, 155, 255, 0.4) 0%,
                          rgba(230, 155, 255, 0.2) 100%
          );
        }
      }
    }
  }

  &-center {
    display: flex;
    align-items: center;
    padding: 30px 66px 30px 64px;
    position: relative;

    @media(max-width: 1200px) {
      padding: 30px 40px 30px 40px;
    }
    @media(max-width: 992px) {
      flex-wrap: wrap;
      padding: 20px;
    }

    .percent {
      margin-right: auto;

      @media(max-width: 992px) {
        width: 100%;
        margin-bottom: 16px;
      }

      .label {
        font-size: 16px;
        line-height: 24px;
        color: rgba(230, 155, 255, 0.4);
      }

      .text {
        font-weight: bold;
        font-size: 20px;
        line-height: 20px;
        color: #d32ce6;

        span {
          font-size: 16px;
        }
      }
    }

    .is-active {
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      color: #05c40c;
      position: relative;

      &:before {
        @extend %before-after;
        top: 50%;
        left: calc(100% + 6px);
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background: #05c40c;
        box-shadow: 0px 0px 16px #05c40c;
        border-radius: 8px;
        transform: translateY(-50%);
      }

      &.none {
        color: rgba(235, 75, 75, 0.4);

        &:before {
          background: #eb4b4b;
          box-shadow: 0px 0px 16px #eb4b4b;
        }
      }
    }

    .create {
      border: 2px solid rgba(136, 71, 255, 0.24);
      border-radius: 18px;
      font-weight: bold;
      font-size: 14px;
      color: rgba(136, 71, 255, 0.32);
      text-decoration: none;
      padding: 0 16px;
      height: 36px;
      display: flex;
      align-items: center;
      transition: all 0.5s;
      margin-left: 50px;

      &:hover {
        color: #8847ff;
        border-color: #8847ff;
      }
    }

    .delete {
      border: 2px solid rgba(136, 71, 255, 0.24);
      border-radius: 18px;
      font-weight: bold;
      font-size: 14px;
      color: rgba(136, 71, 255, 0.32);
      text-decoration: none;
      padding: 0 16px;
      height: 36px;
      display: flex;
      align-items: center;
      transition: all 0.5s;
      margin-left: 50px;

      @media(max-width: 992px) {
        margin-left: auto;
      }

      &:hover {
        color: #8847ff;
        border-color: #8847ff;
      }
    }
  }

  &-footer {
    background: rgba(57, 0, 64, 0.64);
    border-top: 2px dashed #3f0047;
    display: flex;
    justify-content: center;
    padding: 10px 0;
    border-radius: 0 0 32px 32px;
    margin-top: auto;

    @media(max-width: 768px) {
      padding: 20px;
    }

    .profile-landing-user-stats {
      padding: 0;

      @media(max-width: 768px) {
        flex-wrap: nowrap;
        gap: 16px;
      }

      &:before {
        display: none;
      }

      & > :first-child {
        margin-right: 29px;
        position: relative;

        &:before {
          display: none;
        }
      }

      &-item {
        margin: 0 29px;

        @media(max-width: 768px) {
          margin: 0 !important;
        }

        .num {
          flex-direction: column;
          font-size: 20px;
          line-height: 24px;

          @media(max-width: 768px) {
            font-size: 14px;
          }

          .icon {
            margin-right: 0;
            margin-bottom: 4px;
            height: 32px;
            width: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: linear-gradient(300deg, #8214a8, #ff6ce3);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }

        .text {
          @media(max-width: 768px) {
            font-size: 14px;
          }
        }
      }
    }
  }

  &.empty {
    background: linear-gradient(180deg, #26183e 0%, #0e0029 100%);
    border: 2px solid #270f2b;

    &:before {
      border-color: #270f2b;
      border-left-color: transparent;
    }

    &:after {
      border-color: #270f2b;
      border-right-color: transparent;
    }

    .profile-promocodes-item-footer {
      background: rgba(54, 22, 58, 0.35);
      border-color: #270f2b;
    }
  }
}

.progress-promocodes {
  position: relative;
  padding-top: 40px;
  max-width: 1170px;
  margin: 0 auto 140px auto;
  height: 200px;

  @media(max-width: 1240px) {
    padding: 40px 0 0 0;
  }
  @media(max-width: 992px) {
    min-height: 410px;
  }

  @include before() {
    top: 0;
    left: 0;
    background: linear-gradient(
                    90deg,
                    rgba(32, 8, 66, 0.0001) 0%,
                    #860b95 50%,
                    #870b95 50%,
                    rgba(32, 8, 66, 0.0001) 100%
    );
    height: 2px;
    width: 100%;
  }

  &-line {
    width: 100%;
    height: 40px;
    background: linear-gradient(to bottom, #26104e 0%, #3b1674 53.19%);
    border-radius: 20px;
    position: relative;
    padding: 4px;

    @media(max-width: 992px) {
      background: none;
    }
    @include before() {
      width: calc(100% + 4px);
      height: 44px;
      background: linear-gradient(
                      to bottom,
                      rgba(136, 71, 255, 0) 0%,
                      rgba(136, 71, 255, 0.28) 28%,
                      rgba(136, 71, 255, 1) 100%
      );
      border-radius: 20px;
      z-index: -1;
      top: -2px;
      left: -2px;

      @media(max-width: 992px) {
        display: none;
      }
    }
    @include after() {
      width: 100%;
      height: 40px;
      background: linear-gradient(to bottom, #26104e 0%, #3b1674 53.19%);
      border-radius: 20px;
      position: relative;
      padding: 4px;
      left: 0;
      top: 0;
      display: none;
      z-index: -1;

      @media(max-width: 992px) {
        display: none;
      }
    }

    &-var {
      height: 32px;
      left: 380px;
      top: 1041px;
      min-width: 32px;
      background: #d32ce6;
      box-shadow: inset 0px 8px 4px rgba(255, 255, 255, 0.64),
      inset 0px -12px 12px rgba(46, 12, 107, 0.48);
      border-radius: 16px;
      max-width: 100%;

      @media(max-width: 992px) {
        display: none;
      }
    }

    &-var-steps {
      width: 100%;
      position: absolute;
      top: 2px;

      @media(max-width: 992px) {
        transform: rotate(90deg) matrix(-1, 0, 0, 1, 0, 0);
        top: 260px;
        width: 475px;
        left: calc(100% - 242px);
      }

      & > :nth-child(1) {
        left: 4px;
      }

      & > :nth-child(2) {
        left: 33.3%;
      }

      & > :nth-child(3) {
        left: 66.6%;
      }

      &-item {
        background: linear-gradient(
                        to right,
                        rgba(56, 2, 155, 1) 0%,
                        rgba(104, 31, 238, 1) 100%
        );
        width: 28px;
        height: 28px;
        border-radius: 50%;
        position: absolute;
        top: 4px;
        margin-left: -2px;

        &:before {
          @extend %before-after;
          width: 16px;
          height: 16px;
          border-radius: 50%;
          background: #8847ff;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        &:after {
          @extend %before-after;
          background: linear-gradient(
                          180deg,
                          #8847ff 0%,
                          rgba(136, 71, 255, 0) 100%
          );
          width: 2px;
          height: 166px;
          position: absolute;
          top: 12px;
          left: 50%;
          transform: translateX(-50%);
        }

        &.done {
          background: linear-gradient(
                          to right,
                          rgba(65, 0, 80, 1) 0%,
                          rgba(146, 12, 161, 1) 100%
          );

          &:before {
            background: #d32ce6;
          }

          &:after {
            background: linear-gradient(
                            180deg,
                            #d32ce6 0%,
                            rgba(211, 44, 230, 0) 100%
            );
          }
        }
      }
    }

    &-mob {
      width: 40px;
      height: calc(100% + 95px);
      background: linear-gradient(to right, #26104e 0%, #3b1674 53.19%);
      border-radius: 20px;
      padding: 4px;
      position: absolute;
      right: 3px;
      top: 40px;
      display: none;

      @media(max-width: 992px) {
        @include flex(column-reverse, false, false, false);
      }
      @include before() {
        width: calc(100% + 0px);
        height: calc(100% + 0px);
        top: -3px;
        left: -4px;
        border-radius: 24px;
        border: 4px solid transparent;
        background: linear-gradient(to right, rgba(136, 71, 255, 0.28) 0%, rgba(136, 71, 255, 0.62) 100%) border-box;
        -webkit-mask: linear-gradient(#fff 0 0) padding-box,
        linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
      }

      &-var {
        width: 32px;
        min-width: 32px;
        background: #d32ce6;
        box-shadow: inset 0px 8px 4px rgba(255, 255, 255, 0.64), inset 0px -12px 12px rgba(46, 12, 107, 0.48);
        border-radius: 16px;
        max-width: 100%;
      }
    }
  }
}

.progress-promocodes-line-var-steps-content {
  margin-top: 28px;
  display: flex;

  @media(max-width: 992px) {
    flex-direction: column-reverse;
    gap: 44px;
  }

  &-item {
    padding-left: 32px;
    width: 33.3%;

    @media(max-width: 992px) {
      width: 100%;
      padding-left: 0;
      padding-right: 50px;
    }

    &.done {
      .title {
        color: #d32ce6;

        .icon {
          background: linear-gradient(300deg, #8214a8, #ff6ce3);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }

      .your-prize {
        .num {
          color: #d32ce6;
        }
      }
    }
  }

  .title {
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 20px;
    line-height: 20px;
    color: #8847ff;
    padding-left: 4px;
    margin-bottom: 5px;

    .icon {
      background: linear-gradient(300deg, #5119b8, #8847ff);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: 30px;
      line-height: 30px;
      margin-right: 4px;
      color: #8847ff;
    }
  }

  .count {
    font-size: 16px;
    line-height: 24px;
    color: rgba(230, 155, 255, 0.4);
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 10px;

    @media(max-width: 992px) {
      flex-wrap: nowrap;
      font-size: 12px;
    }
    span {
      margin: 0 5px;
      white-space: nowrap;
    }

    i {
      margin-right: 5px;
    }

    div {
      width: 100%;
    }
  }

  .your-prize {
    display: flex;
    font-size: 16px;
    line-height: 24px;
    color: rgba(230, 155, 255, 0.4);

    @media(max-width: 992px) {
      font-size: 12px;
    }

    &>:first-child {
      max-width: 125px;
      width: 100%;
    }
    .num {
      color: #8847ff;
      margin-left: 6px;
    }
  }
}

.promocodes-conclusion-table {
  max-width: 960px;
  margin: 0 auto 36px auto;
  position: relative;

  .table-head {
    display: flex;
    align-items: center;
    font-size: 16px;
    line-height: 24px;
    color: rgba(230, 155, 255, 0.4);
    height: 64px;
    margin-bottom: 4px;
    position: relative;

    &-td {
      @media(max-width: 660px) {
        div {
          display: none;
        }
      }

      .mob {
        display: none;

        @media(max-width: 660px) {
          @include flex(column, false, false, false);
        }
      }
    }

    &:before {
      @extend %before-after;
      background: linear-gradient(
                      90deg,
                      rgba(32, 8, 66, 0.0001) 0%,
                      #860b95 50%,
                      #870b95 50%,
                      rgba(32, 8, 66, 0.0001) 100%
      );
      opacity: 0.4;
      width: 100%;
      height: 2px;
      pointer-events: none;
      top: 0;
    }

    &:after {
      @extend %before-after;
      background: radial-gradient(
                      50% 79.2% at 50% 100%,
                      rgba(230, 44, 199, 0.24) 0%,
                      rgba(21, 5, 49, 0.0001) 100%
      );
      opacity: 0.4;
      width: 100%;
      height: 100%;
      top: 0;
    }

    & > :nth-child(1) {
      width: 160px;
      margin-right: 87px;

      @media(max-width: 660px) {
        width: 50%;
        margin-right: 0;
      }
    }

    & > :nth-child(2) {
      width: 300px;
      margin-right: 50px;

      @media(max-width: 660px) {
        display: none;
      }
    }

    & > :nth-child(3) {
      margin-right: 47px;
      text-align: right;
      width: 160px;

      @media(max-width: 480px) {
        display: none;
      }
    }

    & > :nth-child(4) {
      text-align: right;
      margin-left: auto;

      @media(max-width: 660px) {
        div {
          display: block;
        }
        .mob {
          display: none;
        }
      }
      @media(max-width: 480px) {
        div {
          display: none;
        }
        .mob {
          display: flex;
        }
      }
    }
  }

  .table-body-row {
    display: flex;
    min-height: 64px;
    background: radial-gradient(
                    50% 8814.97% at 50% 50%,
                    rgba(211, 44, 230, 0.04) 0%,
                    rgba(211, 44, 230, 0) 100%
    );
    margin-bottom: 4px;

    .table-body-td {
      font-size: 16px;
      line-height: 24px;
      color: #8847ff;
      display: flex;
      align-items: center;

      @media(max-width: 660px) {
        padding: 16px 0;
      }

      .badge-cost {
        white-space: nowrap;
      }

      .status {
        &.done {
          color: #4b69ff;
        }

        &.cancel {
          color: rgba(235, 75, 75, 0.4);
        }
      }

      .user {
        display: flex;
        align-items: center;
        text-decoration: none;
        color: #ccc;
        max-width: 100%;

        &-ava {
          display: flex;
          align-items: center;
          margin-right: 16px;
          border-radius: 50%;
          overflow: hidden;
          border: 4px solid #31004d;
          width: 34px;
          height: 34px;
          min-width: 34px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        &-name {
          max-width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }

    & > :nth-child(1) {
      width: 160px;
      margin-right: 87px;
      color: rgba(213, 185, 255, 0.5);

      .pay-method {
        display: none;
        margin-top: 10px;

        &-img {
          width: 52px;
          height: 24px;
          margin-right: 16px;
        }
      }

      .user {
        display: none;
      }

      @media(max-width: 660px) {
        width: 50%;
        margin-right: 0;
        @include flex(column, flex-start, false, false);

        .pay-method {
          display: flex;
        }
        .user {
          margin-top: 12px;
          display: flex;
        }
      }
    }

    & > :nth-child(2) {
      width: 300px;
      margin-right: 50px;

      @media(max-width: 660px) {
        display: none;
      }
    }

    & > :nth-child(3) {
      margin-right: 47px;
      text-align: right;
      justify-content: flex-end;
      width: 160px;

      @media(max-width: 480px) {
        display: none;
      }
    }

    & > :nth-child(4) {
      text-align: right;
      margin-left: auto;

      .status + .badge-cost,
      .badge-cost.purpule {
        display: none;
      }

      @media(max-width: 480px) {
        @include flex(column, false, false, false);

        .status {
          margin-bottom: 12px;

          & + .badge-cost {
            display: flex;
          }
        }
        .badge-cost.purpule {
          display: flex;
          margin-bottom: 14px;
        }
      }
    }
  }

  .pay-method {
    display: flex;
    align-items: center;

    &-img {
      width: 84px;
      height: 40px;
      border: 2px solid rgba(211, 44, 230, 0.12);
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 20px;

      img {
        max-width: 100%;
        height: auto;
      }
    }
  }
}

.profile-landing-how {
  display: flex;
  justify-content: center;
  margin-bottom: 64px;

  iframe {
    width: 720px;
    display: block;
    margin: 0 auto;

    @media(max-width: 1200px) {
      width: 100%;
    }
  }
}

.pay-form-method {
  padding: 14px 28px 0 26px;
  display: flex;
  align-items: center;
  margin-bottom: 23px;

  @media(max-width: 992px) {
    padding: 8px 12px 8px 60px !important;
    justify-content: space-between;
    position: relative;
    background: rgba(152, 10, 125, 0.2);
    border-radius: 8px;
  }

  &-back {
    background: rgba(11, 3, 28, 0.2);
    width: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    display: none;

    .icon {
      color: rgba(230, 155, 255, 0.4);
    }

    @media(max-width: 992px) {
      display: flex;
    }
  }

  &-wrap {
    position: relative;
  }

  &-logo img {
    width: 154px;
    height: 60px;
    object-fit: contain;

    @media(max-width: 480px) {
      width: 76px !important;
    }
  }

  &-title {
    font-size: 14px;
    line-height: 20px;
    color: #8a4592;
    border: 1px solid rgba(211, 44, 230, 0);
    text-shadow: 0px 0px 32px #d32ce6;
    margin-bottom: 5px;

    @media(max-width: 992px) {
      text-shadow: none;
      border: 0;
    }
  }

  &-name {
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    color: #ffc600;
  }

  &-info {
    margin-right: 20px;
  }
}

.pay-methods-items {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;

  @media(max-width: 480px) {
    margin: 0 -4px;
  }
}

.pay-methods-item {
  user-select: none;
  width: calc(33.3% - 20px);
  height: 117px;
  border-radius: 8px;
  background: linear-gradient(#310355 0%, #37005a 0.12%, #13003f 100%);
  margin: 0 10px 20px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  color: #736687;
  text-decoration: none;
  position: relative;
  padding-top: 10px;
  transition: all .3s;

  @media(max-width: 480px) {
    height: 80px;
    padding-top: 0;
    font-weight: 400;
    font-size: 12px;
    line-height: 100%;
    margin: 0 4px 8px 4px;
    width: calc(33.3% - 8px);
  }

  .disabled-block {
    display: none;
  }

  .bonus {
    margin-top: -25px;

    @media(max-width: 480px) {
      margin-top: -2px;
    }

    &-percents {
      width: 39px;
      height: 20px;
      margin-right: 2px;

      @media(max-width: 480px) {
        width: 32px;
        height: 16px;
      }
    }

    &-icon {
      width: 20px;
      height: 20px;

      @media(max-width: 480px) {
        width: 16px;
        height: 16px;
      }
    }
  }

  &-popup {
    .text {
      color: rgba(230, 155, 255, 0.4);
      margin-bottom: 2px;
    }

    &.active {
      .text {
        color: #d32ce6;
      }
    }

    &:hover {
      .text {
        color: #d32ce6;
      }
    }

    &.disabled {
      pointer-events: none;

      .icon {
        background: linear-gradient(300deg, #8214a8, #ff6ce3);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.25)) drop-shadow(0px 0px 78px #d32ce6);
        margin-bottom: 3px;
      }

      .disabled-block {
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 3;
        background: #1e0049;
        opacity: 0.8;
        border-radius: 8px;

        .text {
          color: #d8c2ff;
        }
      }
    }
  }

  &-label {
    align-items: center;
    background: #ffc64b;
    border: 2px solid #fffc00;
    border-radius: 4px;
    color: #050b16;
    display: flex;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    height: 20px;
    left: 50%;
    padding: 1px 6px;
    position: absolute;
    text-align: center;
    top: -13px;
    transform: translateX(-50%);
    z-index: 2;
    white-space: nowrap;
  }

  img {
    margin-bottom: 15px;
    position: relative;
    z-index: 2;
    width: 152px;
    max-width: 85%;
    height: 56px;
    object-fit: contain;

    @media(max-width: 480px) {
      margin-bottom: 8px;
      height: 40px;
      max-width: 90%;
    }
  }

  .text {
    position: relative;
    z-index: 2;
  }

  &-hover {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 2px solid #d32ce6;
    box-shadow: 0 0 12px #d32ce6, inset 0px 0px 24px #d32ce6;
    width: calc(100% + 8px);
    height: calc(100% + 8px);
    margin: -2px 0 0 -2px;
    border-radius: 18px;
    background: linear-gradient(#310355 0%, #37005a 0.12%, #3400ab 100%);
    transition: all 0.5s;
    opacity: 0;

    @media(max-width: 480px) {
      margin: -1px 0 0 0px;
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: calc(100% - 8px);
      height: calc(100% - 8px);
      border-radius: 16px;
      border: 4px solid #fff;
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: calc(100% - 12px);
      height: calc(100% - 12px);
      border-radius: 11px;
      border: 2px solid #d32ce6;
      box-shadow: inset 0px 0px 8px #d32ce6;
      margin-top: 1px;
    }
  }

  &:hover {
    background: linear-gradient(180deg, #310355 0%, #37005A 0.1%, #13003F 100%);
    box-shadow: 0 0 0 4px rgba(211, 44, 230, 0.48);
  }

  &.active {
    box-shadow: none;

    .pay-methods-item-hover {
      @media(min-width: 768px) {
        opacity: 1;
      }
    }

    .text {
      color: #D32CE6;
    }
  }
}

$fRoboto: 'Roboto', sans-serif;
$fRobotoCond: 'Roboto Condensed', sans-serif;

$white: #fff;
$black: #000;
$gray: #ccc;
$light-black: #1a1a15;
$menu-color: #e9e2bf;
$yellow: #d1a452;
$orange: #df4f00;
